<template>
  <b-container>
    <b-row align-h="center" v-show="userAdmin === null">
      <b-col cols="6">
        <b-card header="Ingreso IPS" class="text-center mt-5">
          <b-row align-h="center" class="mt-5 mb-5">
            <b-col cols="12" class="text-left">
              <div class="mx-5">
                <b-form-group label="Email del Usuario" label-for="input-3">
                  <b-form-input v-model="form.email" required></b-form-input>
                </b-form-group>
                <b-form-group label="Clave" label-for="input-3">
                  <b-form-input
                    type="password"
                    v-model="form.password"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="6">
              <b-button class="btn-menu-principal" block @click="loginAdmin()"
                >Ingresar</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="showError">
      <b-col>
        <b-alert dismissible show variant="danger">{{
          errorDescripcion
        }}</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import axios from "axios";

  import moment from "moment";
  export default {
    components: {},
    data() {
      return {
        form: {
          email: null,
          password: null,
        },
        showError: false,
        errorDescripcion: null,
      };
    },
    mounted() {
      if (this.userAdmin !== null) {
        this.$router.replace("/admin/reportes");
      }
    },
    computed: {
      ...mapState(["urlProxyApi", "userAdmin", "fechaIngresoAdmin"]),
    },
    methods: {
      ...mapActions([]),
      loginAdmin() {
        let dataToSend = {
          email: this.form.email,
          password: btoa(this.form.password),
        };
        axios
          .post(`${this.urlProxyApi}/admin/auth`, dataToSend)
          .then((response) => {
            if (response.data.success === true) {
              localStorage.setItem("userAdmin", response.data.data.email);
              localStorage.setItem("fechaIngresoAdmin", moment());
              this.$router.push("/admin/reportes");
            } else {
              this.errorDescripcion = response.data.message;
              this.showError = true;
            }
          })
          .catch((error) => {
            this.errorDescripcion = response.data.message;
            this.showError = true;
          });
      },
    },
  };
</script>
<style></style>
